.keypad {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .keypad-row {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;        
        justify-content: center;
        width: 100%;
    }

    .keypad-key {
        width: 40px; /* Default Width */
        height: 40px; /* Default Height */

        align-items: center;
        border: 1px solid #888;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 8px;
        padding: 8px;
    }
}