@import '../NumericKeyBoard.styles.scss';

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: calc($safe-area-inset-bottom + 6px);
  width: 100%;
  background-color: $light-gray;
  box-sizing: border-box;
  max-width: $max-keyboard-width;
  margin: 0 auto;
}
.spacedMode {
  column-gap: 6px;
  row-gap: 6px;
  padding-top: 6px;
  padding-right: 6px;
  padding-left: 6px;
}

.disabledLayout {
  position: absolute;
  z-index: $overlay-z-index;
  width: 100%;
  height: 100%;
}

.text {
  font-size: $font-medium;
  text-align: center;
  font-weight: normal;
  font-family: sans-serif;
  color: $dark;
  margin: 2.5vh 0;
}

.suggestions {
  height: 82px;
  background: white;
  box-shadow: 0px -2.99854px 7.49634px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}