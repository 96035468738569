//colors
$light: #ffffff;
$dark: #1e1e1e;
$silence-color: #e5e5ea;
$border-color: #d8dadc;
$light-gray: #d5d7dd;
//font size
$font-large: 30px;
$font-medium: 24px;
//safe areas
$safe-area-inset-bottom: env(safe-area-inset-bottom);
//flex basis
$default-basis: 33.333333%;
//z-index
$default-z-index: 100;
$overlay-z-index: 101;
//width
$max-keyboard-width: 480px;
//box shadow
$default-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
  rgba(0, 0, 0, 0.24) 0px 1px 2px;
