@import '../NumericKeyBoard.styles.scss';

.KeyboardItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1 0 30%;
  cursor: pointer;
  margin-top: -1px;
  margin-left: -1px;
  border: 1px solid $border-color;
  box-sizing: border-box;
  &:active {
    background: $silence-color;
  }
}

.deactiveMode {
  &:active {
    background: unset;
  }
  cursor: initial;
}

.isNumber {
  background-color: $light;
}

.spacedMode {
  border-radius: 5px;
  box-shadow: $default-shadow;
}
