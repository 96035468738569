@import '../NumericKeyBoard.styles.scss';
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: $default-z-index;
  background-color: $light-gray;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.fullWidth {
  max-width: unset;
}

.startAnimation {
  animation: start-animation normal linear;
}
.closeAnimation {
  animation: close-animation normal linear;
  animation-fill-mode: forwards;
}

@keyframes close-animation {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes start-animation {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
